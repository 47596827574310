import { ChakraProvider, Flex, theme } from "@chakra-ui/react";
import { createClient, Provider } from "urql";
import { Location, RouteComponentProps, Router } from "@reach/router";
import { createUrqlClient } from "../../utils/createUrqlClient";
import { Login } from "../login/login";
import { useIsAuth } from "../../utils/useIsAuth";
import { Register } from "../login/register";
import { ForgotPwd } from "../login/forgotPwd";
import { Home } from "../home/home";

const client = createClient(createUrqlClient());

export const App = () => (
  <ChakraProvider theme={theme}>
    <Location>
      {({ location }) => (
        <Provider value={client}>
          <Router location={location} style={{ minHeight: "100vh" }}>
            <Login path="/login" />
            <Register path="/register" />
            <ForgotPwd path="/forgot" />
            <Home path="/" />
            <NotFound path="/*" />
          </Router>
        </Provider>
      )}
    </Location>
  </ChakraProvider>
);

const NotFound: React.FC<RouteComponentProps> = () => {
  useIsAuth();
  return <Flex>Pagina não encontrada!</Flex>;
};
