import { Button, Flex, Heading, Image, Link, useToast } from "@chakra-ui/react";
import { navigate, RouteComponentProps } from "@reach/router";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import logo from "../../assets/logo.png";
import InputField from "../../components/InputField";
import {
  useConfirmResetPasswordMutation,
  useRequestResetPasswordMutation,
} from "../../generated/graphql";

export const ForgotPwd: React.FC<RouteComponentProps> = () => {
  const [isConfirming, setIsConfirming] = useState(false);
  const [emailConfirm, setEmailConfirm] = useState("");

  const [, requestPwd] = useRequestResetPasswordMutation();
  const [, confirmPwd] = useConfirmResetPasswordMutation();
  const toast = useToast();

  return (
    <Flex
      direction="column"
      mx="auto"
      justifyContent="center"
      alignItems="center"
      minH="100vh"
      px="0.5rem"
      backgroundColor="green.300"
    >
      <Flex
        direction="column"
        px="0.5rem"
        py="0.5rem"
        maxWidth={["100%", "40%"]}
        backgroundColor="white"
        rounded="md"
      >
        <Image src={logo} maxWidth="25ch" mx="auto" />
        <Heading>Recuperar senha no Painel do usuário</Heading>
        {!isConfirming && (
          <Formik
            initialValues={{
              email: "",
              cpfcnpj: "",
            }}
            onSubmit={async (values, { setErrors }) => {
              let cpfcnpj = `${values.cpfcnpj}`.replace(/[^0-9]/g, "").trim();
              if (cpfcnpj.length === 11) {
                // CPF
                cpfcnpj = `${cpfcnpj.substring(0, 3)}.${cpfcnpj.substring(
                  3,
                  6
                )}.${cpfcnpj.substring(6, 9)}-${cpfcnpj.substring(9, 11)}`;
              } else {
                // CNPJ
                cpfcnpj = `${cpfcnpj.substring(0, 2)}.${cpfcnpj.substring(
                  2,
                  5
                )}.${cpfcnpj.substring(5, 8)}/${cpfcnpj.substring(
                  8,
                  12
                )}-${cpfcnpj.substring(12, 14)}`;
              }
              console.log("register submit", values, cpfcnpj);
              const res = await requestPwd({
                email: values.email,
                cpfcnpj,
              });
              if (res.error) {
                setErrors({
                  email: res.error.message.replace("[GraphQL]", ""),
                });
                return;
              }
              setEmailConfirm(values.email);
              setIsConfirming(true);
            }}
          >
            {(props) => (
              <Form>
                <InputField
                  autoFocus
                  name="cpfcnpj"
                  placeholder="CPF/CNPJ"
                  label="CPF/CNPJ"
                  mask={
                    props.values.cpfcnpj.replace(/[^0-9]/g, "").trim().length >
                    11
                      ? "99.999.999/9999-99"
                      : "999.999.999-999"
                  }
                />
                <InputField
                  autoFocus
                  name="email"
                  placeholder="Email"
                  label="Email"
                />
                <Button
                  mt={4}
                  ml="auto"
                  colorScheme="teal"
                  isLoading={props.isSubmitting}
                  type="submit"
                >
                  Recuperar senha
                </Button>
                <Flex
                  mt="1rem"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Link href="/login">Login</Link>
                </Flex>
              </Form>
            )}
          </Formik>
        )}
        {isConfirming && (
          <Formik
            initialValues={{
              email: emailConfirm,
              novaSenha: "",
              codConfirmacao: "",
            }}
            onSubmit={async (values, { setErrors }) => {
              console.log("confirmation submit", values);
              const res = await confirmPwd({
                email: emailConfirm,
                novaSenha: values.novaSenha,
                codConfirmacao: values.codConfirmacao,
              });
              if (res.error) {
                setErrors({
                  novaSenha: res.error.message.replace("[GraphQL]", ""),
                });
                return;
              }
              toast({
                title: "Confirmado com sucesso!",
                description: "Entre com seu email e senha criados agora!",
                status: "success",
                duration: 9000,
                isClosable: true,
              });
              navigate("/login");
            }}
          >
            {(props) => (
              <Form>
                <Heading size="xs">
                  Digite o código de confirmação que enviamos em seu email
                </Heading>
                <InputField
                  name="email"
                  placeholder="Email"
                  label="Email"
                  disabled
                />
                <InputField
                  autoFocus
                  name="codConfirmacao"
                  placeholder="Código de Confirmação"
                  label="Código de Confirmação"
                />
                <InputField
                  name="novaSenha"
                  placeholder="Nova Senha"
                  type="password"
                  label="Nova Senha"
                />
                <Button
                  mt={4}
                  ml="auto"
                  colorScheme="teal"
                  isLoading={props.isSubmitting}
                  type="submit"
                >
                  Criar conta
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </Flex>
    </Flex>
  );
};
