import modulo10 from "./modulo10";

export function montaLinha(linhaDigitavel: string) {
  let p1 = linhaDigitavel.substr(0, 4);
  let p2 = linhaDigitavel.substr(19, 5);
  let p3 = `${modulo10(`${p1}${p2}`)}`;
  let p4 = `${p1}${p2}${p3}`;
  let p5 = p4.substr(0, 5);
  let p6 = p4.substr(5);
  const campo1 = `${p5}.${p6}`;

  p1 = linhaDigitavel.substr(24, 10);
  p2 = `${modulo10(p1)}`;
  p3 = `${p1}${p2}`;
  p4 = p3.substr(0, 5);
  p5 = p3.substr(5);
  const campo2 = `${p4}.${p5}`;

  p1 = linhaDigitavel.substr(34, 10);
  p2 = `${modulo10(p1)}`;
  p3 = `${p1}${p2}`;
  p4 = p3.substr(0, 5);
  p5 = p3.substr(5);
  const campo3 = `${p4}.${p5}`;

  const campo4 = linhaDigitavel.substr(4, 1);

  const campo5 = linhaDigitavel.substr(5, 14);

  return `${campo1} ${campo2} ${campo3} ${campo4} ${campo5}`;
}
