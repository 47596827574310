import { usePainelClienteQuery } from "../generated/graphql";
import { useEffect } from "react";
import { navigate } from "@reach/router";

export const useIsAuth = () => {
  const [{ data, fetching }] = usePainelClienteQuery();
  useEffect(() => {
    console.log("debug login", fetching, data);
    if (!fetching && !data?.painelCliente) {
      console.log("redirecting to login");
      navigate("/login?next=" + window.location.pathname);
    }
  }, [fetching, data]);
};
