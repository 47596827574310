import { formataNumero } from "./formataNumero";
import formatDate from "./formatDate";

export function calcNossoNumero(
  numeroBol: string,
  agencia: string,
  posto: string,
  codCliente: string,
  banco: string,
  dataProcessamento: Date = new Date()
) {
  const nSeq = "2";
  let nossoNumero = formataNumero(numeroBol, 5);
  const anoGeracao = formatDate(dataProcessamento, "yy");
  switch (banco) {
    case "748": // Sicredi
      nossoNumero = `${anoGeracao}${nSeq}${nossoNumero}`;

      const sequencia =
        formataNumero(agencia, 4) +
        formataNumero(posto, 2) +
        formataNumero(codCliente, 5) +
        nossoNumero;
      let calculoDv = 0;
      console.log("sequencia nosso numero", nossoNumero, sequencia);

      let ncont = 2;
      for (let n = sequencia.length - 1; n >= 0; n--) {
        calculoDv += parseInt(sequencia.substr(n, 1)) * ncont;
        ncont = ncont >= 9 ? 2 : ncont + 1;
      }
      const dv = 11 - (calculoDv % 11);

      // const dv = modulo11(sequencia);
      return `${nossoNumero}${dv > 9 ? 0 : dv}`;
    case "756": // Sicoob
      let nossoNumero756 = formataNumero(numeroBol, 7);
      nossoNumero = `${anoGeracao}${nSeq}${nossoNumero}`;

      const sequencia756 =
        formataNumero(agencia, 4) +
        formataNumero(codCliente, 10) +
        nossoNumero756;
      let calculoDv756 = 0;
      let cont = 3;
      for (let n = 0; n < sequencia756.length; n++) {
        calculoDv756 += parseInt(sequencia756.substr(n, 1)) * cont;
        switch (cont) {
          case 3:
            cont = 1;
            break;
          case 1:
            cont = 9;
            break;
          case 9:
            cont = 7;
            break;
          case 7:
            cont = 3;
            break;
        }
      }
      const dv756 = 11 - (calculoDv756 % 11);

      // const dv = modulo11(sequencia);
      return `${nossoNumero756}${dv756 > 9 ? 0 : dv756}`;
    default:
      return `${numeroBol}`;
  }
}
