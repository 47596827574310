import React from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { useField } from "formik";
import { InputHTMLAttributes } from "react";
import InputMask from "react-input-mask";

type InputFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  label: string;
  textarea?: boolean;
  mask?: string | (string | RegExp)[];
};

const InputField: React.FC<InputFieldProps> = ({
  label,
  textarea,
  size: _,
  mask,
  ...props
}) => {
  const [field, { error }, { setValue }] = useField(props);
  let C = (
    <Input
      value={field.value}
      onChange={(e) => setValue(e.target.value, false)}
      id={field.name}
      {...props}
    />
  );
  if (!!mask) {
    C = (
      <InputMask
        value={field.value}
        onChange={(e) => setValue(e.target.value, false)}
        maskPlaceholder=" "
        {...props}
        mask={mask}
        id={field.name}
      >
        <Input />
      </InputMask>
    );
  }
  if (textarea) {
    C = <Textarea {...field} id={field.name} placeholder={props.placeholder} />;
  }
  return (
    <FormControl isInvalid={!!error} mt="0.5em">
      <FormLabel htmlFor={field.name}>{label}</FormLabel>
      {C}
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default InputField;
