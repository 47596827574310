import {
  Box,
  Button,
  Flex,
  Text,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  Image,
  Link,
} from "@chakra-ui/react";
import { RouteComponentProps } from "@reach/router";
import React, { useState } from "react";
import {
  usePainelClienteQuery,
  useCriarPixPainelMutation,
  Cobranca,
} from "../../generated/graphql";
import { useIsAuth } from "../../utils/useIsAuth";
import _ from "lodash";
import { formatNumber } from "../../utils/formatNumber";
import formatDate from "../../utils/formatDate";
import { linhaDigitavel } from "../../utils/linhaDigitavel";
import { montaLinha } from "../../utils/montaLinha";
import { faCopy, faQrcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaWhatsapp } from "react-icons/fa";
import qrcode from "qrcode";

function b64toBlob(
  b64Data: string,
  contentType: string | null = null,
  sliceSize: number | null = null
) {
  contentType = contentType || "image/png";
  sliceSize = sliceSize || 512;
  let byteCharacters = atob(b64Data);
  let byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);
    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
}

export const Home: React.FC<RouteComponentProps> = () => {
  useIsAuth();

  const toast = useToast();
  const [fetching, setFetching] = useState(false);

  const [{ data }] = usePainelClienteQuery();

  const cobrancas = data?.painelCliente?.contratos?.map(
    (contrato) => contrato.cobrancas
  );
  const cobrancasArray = cobrancas && _.union(...cobrancas);
  console.log("cobrancas", cobrancas, cobrancasArray);

  const [, criarPix] = useCriarPixPainelMutation();
  const [pixToShow, setPixToShow] = useState<{
    qrCode: string;
    copiaECola: string;
    id: number;
  } | null>(null);

  const criarPixCobranca = async (cobranca: Cobranca) => {
    setFetching(true);
    if (cobranca.qrCode && cobranca.copiaECola) {
      setPixToShow({
        qrCode: cobranca.qrCode,
        copiaECola: cobranca.copiaECola,
        id: cobranca.id || 0,
      });
      setFetching(false);
      return;
    }
    if (!cobranca.id) {
      setFetching(false);
      return;
    }

    const { error, data } = await criarPix({ id: cobranca.id });
    if (error) {
      toast({
        duration: 9000,
        title: `Erro ao gerar PIX da cobrança: ${cobranca.id}!`,
        description: `Ocorreu o seguinte erro: ${error.message}`,
        status: "error",
      });
      setFetching(false);
      return;
    }
    if (data && data.criarPixPainel && data.criarPixPainel.copiaECola) {
      const b64qrcode = await qrcode.toDataURL(data.criarPixPainel.copiaECola);
      setPixToShow({
        qrCode: b64qrcode,
        copiaECola: data.criarPixPainel.copiaECola,
        id: cobranca.id,
      });
    }
    setFetching(false);
  };

  const handleImgCopyToClipboard = async () => {
    try {
      if ("clipboard" in navigator && pixToShow) {
        const blob = b64toBlob(pixToShow.qrCode, "image/png");
        const item = new ClipboardItem({ "image/png": blob });
        await navigator.clipboard.write([item]);
        toast({
          title: "QRCode copiado",
          description: `QRCode copiado`,
          status: "success",
        });
      }
    } catch (error) {
      console.error("error on copy", error);
    }
  };

  const handleTxtCopyToClipboard = async () => {
    try {
      if ("clipboard" in navigator && pixToShow) {
        await navigator.clipboard.writeText(pixToShow.copiaECola);
        toast({
          title: "Copia-e-cola copiado",
          description: `Copia-e-cola copiado`,
          status: "success",
        });
      }
    } catch (error) {
      console.error("error on copy", error);
    }
  };

  const handleCopyBarCode = async (codBarras: string) => {
    try {
      if ("clipboard" in navigator && codBarras) {
        await navigator.clipboard.writeText(codBarras.replace(/[^0-9]/g, ""));
        toast({
          title: "Código de barras copiado",
          description: `Código de barras copiado`,
          status: "success",
        });
      }
    } catch (error) {
      console.error("error on copy", error);
    }
  };

  return (
    <Flex direction="column" minH="100vh">
      <Modal isOpen={!!pixToShow} onClose={() => setPixToShow(null)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>QrCode do PIX</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Leia no app do seu banco o QRCode abaixo</Text>
            <Image src={`${pixToShow && pixToShow.qrCode}`} />
            <FontAwesomeIcon
              icon={faCopy}
              onClick={() => handleImgCopyToClipboard()}
            />
            <Text>
              <FontAwesomeIcon
                icon={faCopy}
                onClick={() => handleTxtCopyToClipboard()}
              />{" "}
              URL Copia-e-cola: {pixToShow && pixToShow.copiaECola}
            </Text>
            <Link
              href={`whatsapp://send?text=Abra o link para seu código PIX: https://bug.gabrimar.com.br/pix/${
                pixToShow ? pixToShow.id : 0
              }`}
            >
              <FaWhatsapp style={{ display: "inline" }} /> Compartilhar via
              whatsapp
            </Link>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Flex
        minW="100vw"
        justifyContent="center"
        alignItems="center"
        py="0.5rem"
        backgroundColor="blue.900"
      >
        <Text flexGrow={1} textAlign="center" textColor="white">
          Bem vindo, {data?.painelCliente?.nome}
        </Text>
        <Button rounded="md" type="button" ml="2rem" mr="1rem">
          Sair
        </Button>
      </Flex>
      <Flex backgroundColor="gray.100" grow="1" direction="row">
        <Flex
          direction="column"
          borderWidth="1px"
          rounded="md"
          margin="1rem"
          padding="0.5rem"
          grow="1"
        >
          <Text textAlign="center" fontSize="xl" mb="1.5rem">
            Contratos ativos
          </Text>
          {data?.painelCliente?.contratos
            ?.filter((contrato) => contrato.isAtivado && !contrato.isCanceled)
            .map((contrato, i) => (
              <Box
                key={`${i}`}
                borderWidth="1px"
                borderRadius="lg"
                mx="0.25rem"
                padding="0.5rem"
                backgroundColor="gray.300"
              >
                <Text>
                  Endereço {contrato.logradouro}, {contrato.numero}
                </Text>
                <Text>
                  Cidade {contrato.cidade} - {contrato.uf}
                </Text>
                <Text>Status {contrato.isBlocked ? "Bloqueado" : "Ativo"}</Text>
              </Box>
            ))}
        </Flex>
        <Flex
          direction="column"
          rounded="md"
          margin="1rem"
          padding="0.5rem"
          grow="1"
        >
          <Text fontSize="xl" mb="1.5rem" textAlign="center">
            Cobranças em aberto
          </Text>
          {cobrancasArray
            ?.filter((cobranca) => !cobranca.isPago && !cobranca.isCanceled)
            .map((cobranca, i) => (
              <Box
                key={`${i}`}
                borderWidth="1px"
                borderRadius="lg"
                mx="0.25rem"
                padding="0.5rem"
                my="0.2rem"
                backgroundColor="gray.300"
              >
                <Text>Cobrança {cobranca.id}</Text>
                <Text>Vencimento {formatDate(cobranca.vencimento)}</Text>
                <Text>Valor {formatNumber(cobranca.valor, 2, true)}</Text>
                <Text>Status {!cobranca.isPago ? "Em aberto" : "Pago"}</Text>
                {!cobranca.isPago && cobranca.id && (
                  <>
                    <Text>
                      Linha digitavel para pagamento:{" "}
                      {montaLinha(
                        linhaDigitavel(
                          `${cobranca.id}`,
                          cobranca.banco.agencia,
                          cobranca.banco.carteira,
                          cobranca.dvCodClienteBol ||
                            cobranca.banco.codClienteDv,
                          cobranca.codClienteBol || cobranca.banco.codCliente,
                          cobranca.valor,
                          cobranca.vencimento,
                          cobranca.banco.codigo,
                          "",
                          cobranca.data_emissao
                        )
                      )}
                      <FontAwesomeIcon
                        icon={faCopy}
                        style={{ marginLeft: "1rem", cursor: "pointer" }}
                        onClick={() =>
                          handleCopyBarCode(
                            montaLinha(
                              linhaDigitavel(
                                `${cobranca.id}`,
                                cobranca.banco.agencia,
                                cobranca.banco.carteira,
                                cobranca.dvCodClienteBol ||
                                  cobranca.banco.codClienteDv,
                                cobranca.codClienteBol ||
                                  cobranca.banco.codCliente,
                                cobranca.valor,
                                cobranca.vencimento,
                                cobranca.banco.codigo,
                                "",
                                cobranca.data_emissao
                              )
                            )
                          )
                        }
                      />
                    </Text>
                    <Button
                      onClick={() => criarPixCobranca(cobranca as Cobranca)}
                      isLoading={fetching}
                      disabled={fetching}
                    >
                      <FontAwesomeIcon icon={faQrcode} />
                    </Button>
                  </>
                )}
              </Box>
            ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
