import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type AddIteracao = {
  chamado: Scalars['Int'];
  descricao: Scalars['String'];
  novoStatus: StatusChamado;
  valor: Scalars['Float'];
};

export type BaixarCobrancasResponse = {
  __typename?: 'BaixarCobrancasResponse';
  avisos?: Maybe<Array<Scalars['String']>>;
};

export type BaixarInput = {
  id: Scalars['Int'];
  valor_pago: Scalars['Float'];
};

export type Banco = {
  __typename?: 'Banco';
  agencia: Scalars['String'];
  agenciaDv: Scalars['String'];
  carteira: Scalars['String'];
  codCliente: Scalars['String'];
  codClienteDv: Scalars['String'];
  codigo: Scalars['String'];
  conta: Scalars['String'];
  contaDv: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
  nome: Scalars['String'];
  operacao: Scalars['String'];
  percentualCredito: Scalars['Float'];
  percentualDebito: Scalars['Float'];
};

export type BancoInput = {
  agencia: Scalars['String'];
  agenciaDv: Scalars['String'];
  carteira: Scalars['String'];
  codCliente: Scalars['String'];
  codClienteDv: Scalars['String'];
  codigo: Scalars['String'];
  conta: Scalars['String'];
  contaDv: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  nome: Scalars['String'];
  operacao: Scalars['String'];
  percentualCredito: Scalars['Float'];
  percentualDebito: Scalars['Float'];
};

export type BancoResponse = {
  __typename?: 'BancoResponse';
  banco?: Maybe<Banco>;
  errors?: Maybe<Array<FieldError>>;
};

export type Caixa = {
  __typename?: 'Caixa';
  id?: Maybe<Scalars['Int']>;
  nome: Scalars['String'];
};

export type CaixaInput = {
  id?: InputMaybe<Scalars['Int']>;
  nome: Scalars['String'];
};

export type CaixaResponse = {
  __typename?: 'CaixaResponse';
  caixa?: Maybe<Caixa>;
  errors?: Maybe<Array<FieldError>>;
};

export type Chamado = {
  __typename?: 'Chamado';
  cliente: Cliente;
  contrato: Contrato;
  id?: Maybe<Scalars['Int']>;
  inicio: Scalars['DateTime'];
  iteracoes: Array<Iteracao_Chamado>;
  status: StatusChamado;
  tipo: TipoChamado;
};

export type ChamadoResponse = {
  __typename?: 'ChamadoResponse';
  chamado?: Maybe<Chamado>;
  errors?: Maybe<Array<FieldError>>;
};

export type CheckUsuarioResponse = {
  __typename?: 'CheckUsuarioResponse';
  enderecoIp?: Maybe<Scalars['String']>;
  isConnected: Scalars['Boolean'];
  upTimeSeconds: Scalars['Float'];
};

export type Cliente = {
  __typename?: 'Cliente';
  bairro: Scalars['String'];
  cep: Scalars['String'];
  cidade: Scalars['String'];
  cmun: Scalars['String'];
  complemento: Scalars['String'];
  contratos?: Maybe<Array<Contrato>>;
  dn: Scalars['DateTime'];
  email: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
  isActive: Scalars['Boolean'];
  isncEstadual: Scalars['String'];
  isncNacional: Scalars['String'];
  logradouro: Scalars['String'];
  nome: Scalars['String'];
  numero: Scalars['String'];
  obs: Scalars['String'];
  telefone1: Scalars['String'];
  telefone2: Scalars['String'];
  telefone3: Scalars['String'];
  tipo: Scalars['String'];
  uf: Scalars['String'];
};

export type ClienteInput = {
  bairro: Scalars['String'];
  cep: Scalars['String'];
  cidade: Scalars['String'];
  cmun: Scalars['String'];
  complemento: Scalars['String'];
  dn: Scalars['DateTime'];
  email: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  isActive: Scalars['Boolean'];
  isncEstadual: Scalars['String'];
  isncNacional: Scalars['String'];
  logradouro: Scalars['String'];
  nome: Scalars['String'];
  numero: Scalars['String'];
  obs: Scalars['String'];
  telefone1: Scalars['String'];
  telefone2: Scalars['String'];
  telefone3: Scalars['String'];
  tipo: Scalars['String'];
  uf: Scalars['String'];
};

export type ClienteResponse = {
  __typename?: 'ClienteResponse';
  cliente?: Maybe<Cliente>;
  errors?: Maybe<Array<FieldError>>;
};

export type Cobranca = {
  __typename?: 'Cobranca';
  banco: Banco;
  chavePix?: Maybe<Scalars['String']>;
  cliente: Cliente;
  cobrancaImportada?: Maybe<Scalars['Int']>;
  codClienteBol?: Maybe<Scalars['String']>;
  contrato: Contrato;
  copiaECola?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  data_cancelado?: Maybe<Scalars['DateTime']>;
  data_emissao: Scalars['DateTime'];
  data_pago?: Maybe<Scalars['DateTime']>;
  desconto: Scalars['Float'];
  dvCodClienteBol?: Maybe<Scalars['String']>;
  erroRegistro?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  instrucoes?: Maybe<Scalars['String']>;
  isCanceled: Scalars['Boolean'];
  isMensalidade: Scalars['Boolean'];
  isPago: Scalars['Boolean'];
  isPrinted: Scalars['Boolean'];
  isRegistrado: Scalars['Boolean'];
  juros: Scalars['Float'];
  nossoNumero: Scalars['String'];
  qrCode?: Maybe<Scalars['String']>;
  referencia?: Maybe<Scalars['String']>;
  statusPix?: Maybe<Scalars['String']>;
  tarifaBancaria: Scalars['Float'];
  txid?: Maybe<Scalars['String']>;
  usuarioCancelado?: Maybe<Scalars['String']>;
  usuarioCriou?: Maybe<Scalars['String']>;
  usuarioPago?: Maybe<Scalars['String']>;
  valor: Scalars['Float'];
  valor_pago?: Maybe<Scalars['Float']>;
  vencimento: Scalars['DateTime'];
};

export type CobrancaInput = {
  banco: Scalars['Int'];
  cliente: Scalars['Int'];
  contrato: Scalars['Int'];
  desconto: Scalars['Float'];
  id?: InputMaybe<Scalars['Int']>;
  instrucoes: Scalars['String'];
  isMensalidade: Scalars['Boolean'];
  juros: Scalars['Float'];
  referencia: Scalars['String'];
  valor: Scalars['Float'];
  vencimento: Scalars['DateTime'];
};

export type CobrancaResponse = {
  __typename?: 'CobrancaResponse';
  cobranca?: Maybe<Cobranca>;
  errors?: Maybe<Array<FieldError>>;
};

export type Contrato = {
  __typename?: 'Contrato';
  acrescimoMensalidade?: Maybe<Scalars['Float']>;
  bairro: Scalars['String'];
  banco: Banco;
  cep: Scalars['String'];
  cidade: Scalars['String'];
  cliente: Cliente;
  cmun: Scalars['String'];
  cobrancas?: Maybe<Array<Cobranca>>;
  complemento: Scalars['String'];
  dataAtivacao?: Maybe<Scalars['DateTime']>;
  dataCancelamento?: Maybe<Scalars['DateTime']>;
  descontoMensalidade?: Maybe<Scalars['Float']>;
  email: Scalars['String'];
  enviaEmailNF: Scalars['Boolean'];
  estacao?: Maybe<Estacao>;
  id?: Maybe<Scalars['Int']>;
  ip?: Maybe<Scalars['String']>;
  isAtivado: Scalars['Boolean'];
  isBlocked: Scalars['Boolean'];
  isCanceled: Scalars['Boolean'];
  logradouro: Scalars['String'];
  mac?: Maybe<Scalars['String']>;
  motivoCancelamento?: Maybe<MotivoCancelamento>;
  numero: Scalars['String'];
  obs?: Maybe<Scalars['String']>;
  outroMotivoCancelamento?: Maybe<Scalars['String']>;
  plano: Plano;
  telefone1: Scalars['String'];
  telefone2: Scalars['String'];
  telefone3: Scalars['String'];
  tipoAcesso?: Maybe<TipoAcesso>;
  tipoConvenio?: Maybe<TipoConvenio>;
  uf: Scalars['String'];
  usuario?: Maybe<Scalars['String']>;
  vlan?: Maybe<Scalars['String']>;
};

export type ContratoInput = {
  acrescimoMensalidade?: InputMaybe<Scalars['Float']>;
  bairro: Scalars['String'];
  banco: BancoInput;
  cep: Scalars['String'];
  cidade: Scalars['String'];
  cliente: ClienteInput;
  cmun: Scalars['String'];
  complemento: Scalars['String'];
  dataAtivacao?: InputMaybe<Scalars['DateTime']>;
  dataCancelamento?: InputMaybe<Scalars['DateTime']>;
  descontoMensalidade?: InputMaybe<Scalars['Float']>;
  email: Scalars['String'];
  enviaEmailNF: Scalars['Boolean'];
  estacao?: InputMaybe<EstacaoInput>;
  id?: InputMaybe<Scalars['Int']>;
  ip?: InputMaybe<Scalars['String']>;
  isAtivado: Scalars['Boolean'];
  isBlocked: Scalars['Boolean'];
  isCanceled: Scalars['Boolean'];
  logradouro: Scalars['String'];
  mac?: InputMaybe<Scalars['String']>;
  motivoCancelamento?: InputMaybe<MotivoCancelamento>;
  numero: Scalars['String'];
  obs?: InputMaybe<Scalars['String']>;
  outroMotivoCancelamento?: InputMaybe<Scalars['String']>;
  plano: PlanoInput;
  telefone1: Scalars['String'];
  telefone2: Scalars['String'];
  telefone3: Scalars['String'];
  tipoAcesso?: InputMaybe<TipoAcesso>;
  tipoConvenio?: InputMaybe<TipoConvenio>;
  uf: Scalars['String'];
  usuario?: InputMaybe<Scalars['String']>;
  vlan?: InputMaybe<Scalars['String']>;
};

export type ContratoResponse = {
  __typename?: 'ContratoResponse';
  contrato?: Maybe<Contrato>;
  errors?: Maybe<Array<FieldError>>;
};

export type CreateChamado = {
  cliente: Scalars['Int'];
  contrato: Scalars['Int'];
  descricao: Scalars['String'];
  statusInicial: StatusChamado;
  tipo: TipoChamado;
};

export type DateRangeInput = {
  fim: Scalars['DateTime'];
  inicio: Scalars['DateTime'];
};

export type Estacao = {
  __typename?: 'Estacao';
  estacaoMestre?: Maybe<Estacao>;
  id?: Maybe<Scalars['Int']>;
  ip: Scalars['String'];
  isHotspot: Scalars['Boolean'];
  isPPP: Scalars['Boolean'];
  isVLAN: Scalars['Boolean'];
  nome: Scalars['String'];
  tipo: TipoEstacao;
  usuario: Scalars['String'];
};

export type EstacaoInput = {
  estacaoMestre?: InputMaybe<EstacaoInput>;
  id?: InputMaybe<Scalars['Int']>;
  ip: Scalars['String'];
  isHotspot: Scalars['Boolean'];
  isPPP: Scalars['Boolean'];
  isVLAN: Scalars['Boolean'];
  nome: Scalars['String'];
  tipo: TipoEstacao;
  usuario: Scalars['String'];
};

export type EstacaoResponse = {
  __typename?: 'EstacaoResponse';
  errors?: Maybe<Array<FieldError>>;
  estacao?: Maybe<Estacao>;
};

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type ItemNota = {
  __typename?: 'ItemNota';
  acrescimo: Scalars['Float'];
  aliquotaicms: Scalars['Float'];
  cfop: Scalars['String'];
  desconto: Scalars['Float'];
  icms: Scalars['Float'];
  id?: Maybe<Scalars['Int']>;
  produto?: Maybe<Produto>;
  quantidade: Scalars['Float'];
  valor_total: Scalars['Float'];
  valor_unitario: Scalars['Float'];
};

export type ItemNotaInput = {
  acrescimo: Scalars['Float'];
  aliquotaicms: Scalars['Float'];
  cfop: Scalars['String'];
  desconto: Scalars['Float'];
  icms: Scalars['Float'];
  id?: InputMaybe<Scalars['Int']>;
  produto?: InputMaybe<ProdutoInput>;
  quantidade: Scalars['Float'];
  valor_total: Scalars['Float'];
  valor_unitario: Scalars['Float'];
};

export type ItemResultadoProcessoWs = {
  __typename?: 'ItemResultadoProcessoWS';
  cobranca: Scalars['Int'];
  jsonBanco: Scalars['String'];
  mensagem: Scalars['String'];
  nossoNumero: Scalars['String'];
  seuNumero: Scalars['String'];
  situacaoBanco: Scalars['String'];
};

export type Iteracao_Chamado = {
  __typename?: 'Iteracao_chamado';
  chamado: Chamado;
  data: Scalars['DateTime'];
  descricao: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
  status_anterior?: Maybe<StatusChamado>;
  status_posterior?: Maybe<StatusChamado>;
  usuario: Usuario;
  valor?: Maybe<Scalars['Float']>;
};

export type Lancamento_Caixa = {
  __typename?: 'Lancamento_caixa';
  caixa: Caixa;
  data: Scalars['DateTime'];
  descricao: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
  isFechamento: Scalars['Boolean'];
  valor: Scalars['Float'];
};

export type Lancamento_CaixaInput = {
  caixa: Scalars['Float'];
  data: Scalars['DateTime'];
  descricao: Scalars['String'];
  isDeposito: Scalars['Boolean'];
  valor: Scalars['Float'];
};

export type Lancamento_CaixaResponse = {
  __typename?: 'Lancamento_caixaResponse';
  errors?: Maybe<Array<FieldError>>;
  lancamento_caixa?: Maybe<Lancamento_Caixa>;
};

export type Lancamentobanco = {
  __typename?: 'Lancamentobanco';
  banco: Banco;
  data: Scalars['DateTime'];
  descricao: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
  isFechamento: Scalars['Boolean'];
  valor: Scalars['Float'];
};

export type LancamentobancoInput = {
  banco: Scalars['Float'];
  bancoDestino?: InputMaybe<Scalars['Float']>;
  data: Scalars['DateTime'];
  descricao: Scalars['String'];
  isTransferencia?: InputMaybe<Scalars['Boolean']>;
  valor: Scalars['Float'];
};

export type LancamentobancoResponse = {
  __typename?: 'LancamentobancoResponse';
  errors?: Maybe<Array<FieldError>>;
  lancamentobanco?: Maybe<Lancamentobanco>;
};

export enum MotivoCancelamento {
  Alteracao = 'ALTERACAO',
  Faltaatendimento = 'FALTAATENDIMENTO',
  Inadimplencia = 'INADIMPLENCIA',
  Insatisfacao = 'INSATISFACAO',
  Naoespecificado = 'NAOESPECIFICADO',
  Outros = 'OUTROS',
  Semviabilidade = 'SEMVIABILIDADE',
  Transferencia = 'TRANSFERENCIA',
  Usoimpossibilitado = 'USOIMPOSSIBILITADO'
}

export type Mutation = {
  __typename?: 'Mutation';
  addIteracao?: Maybe<ChamadoResponse>;
  ativarContrato: Scalars['Boolean'];
  baixarCobranca: Scalars['Boolean'];
  baixarCobrancas: BaixarCobrancasResponse;
  baixarPagar: Scalars['Boolean'];
  cancelarCobranca: Scalars['Boolean'];
  cancelarContrato: Scalars['Boolean'];
  changeMyPassword: Scalars['Boolean'];
  changePainelPassword: Scalars['Boolean'];
  checkContrato: CheckUsuarioResponse;
  confirmEmail: Scalars['Boolean'];
  confirmResetPassword: Scalars['Boolean'];
  consultaCobrancaOnline: Scalars['String'];
  createBanco: BancoResponse;
  createCaixa: CaixaResponse;
  createChamado: ChamadoResponse;
  createCliente: ClienteResponse;
  createCobranca: CobrancaResponse;
  createContrato: ContratoResponse;
  createEstacao: EstacaoResponse;
  createLancamento_caixa: Lancamento_CaixaResponse;
  createLancamentobanco: LancamentobancoResponse;
  createNota: NotaResponse;
  createPagar: PagarResponse;
  createPlano: PlanoResponse;
  createProduto: ProdutoResponse;
  createSerie: SerieResponse;
  createUsuario: UsuarioResponse;
  createVelocidade: VelocidadeResponse;
  criarPixImediato: Cobranca;
  criarPixPainel: Cobranca;
  deleteBanco: Scalars['Boolean'];
  deleteCaixa: Scalars['Boolean'];
  deleteChamado: Scalars['Boolean'];
  deleteCliente: Scalars['Boolean'];
  deleteCobranca: Scalars['Boolean'];
  deleteContrato: Scalars['Boolean'];
  deleteEstacao: Scalars['Boolean'];
  deleteLancamento_caixa: Scalars['Boolean'];
  deleteLancamentobanco: Scalars['Boolean'];
  deleteNota: Scalars['Boolean'];
  deletePagar: Scalars['Boolean'];
  deletePlano: Scalars['Boolean'];
  deleteProduto: Scalars['Boolean'];
  deleteSerie: Scalars['Boolean'];
  deleteUsuario: Scalars['Boolean'];
  deleteVelocidade: Scalars['Boolean'];
  emitirCobrancaWS: Scalars['Boolean'];
  fecharBanco: Scalars['Boolean'];
  fecharCaixa: Scalars['Boolean'];
  geraArquivoCNAB: MutationGeraCnabResponse;
  geraArquivosNF21: MutationGeraArquivoResponse;
  geraEEnviaNF: Scalars['Boolean'];
  gerarNotas: Scalars['Int'];
  imprimirFechamento: Scalars['Boolean'];
  imprimirLCaixa: Scalars['Boolean'];
  imprimirRecibo: Scalars['Boolean'];
  leArquivoCNAB: RetornoArquivoResponse;
  login: Usuario;
  loginCliente: Cliente;
  logout: Scalars['Boolean'];
  marcarImpresso: Scalars['Boolean'];
  mudarBancoCobrancas?: Maybe<Scalars['Int']>;
  register: Scalars['Boolean'];
  requestResetPassword: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  syncContrato: Scalars['Boolean'];
  syncEstacao: Scalars['Boolean'];
  testarPlano: Scalars['Boolean'];
  updateBanco?: Maybe<BancoResponse>;
  updateCaixa?: Maybe<CaixaResponse>;
  updateCliente?: Maybe<ClienteResponse>;
  updateCobranca?: Maybe<CobrancaResponse>;
  updateContrato?: Maybe<ContratoResponse>;
  updateEstacao?: Maybe<EstacaoResponse>;
  updateNota?: Maybe<NotaResponse>;
  updatePagar?: Maybe<PagarResponse>;
  updatePlano?: Maybe<PlanoResponse>;
  updateProduto?: Maybe<ProdutoResponse>;
  updateSerie?: Maybe<SerieResponse>;
  updateUsuario?: Maybe<Usuario>;
  updateVelocidade?: Maybe<VelocidadeResponse>;
  verificarEBaixarCobrancas: ResultadoProcessoWs;
};


export type MutationAddIteracaoArgs = {
  iteracao: AddIteracao;
};


export type MutationAtivarContratoArgs = {
  id: Scalars['Int'];
};


export type MutationBaixarCobrancaArgs = {
  bancoid?: InputMaybe<Scalars['Int']>;
  caixaid?: InputMaybe<Scalars['Int']>;
  cartaoCredito?: InputMaybe<Scalars['Float']>;
  cartaoDebito?: InputMaybe<Scalars['Float']>;
  dinheiro?: InputMaybe<Scalars['Float']>;
  id: Scalars['Int'];
  imprimirRecibo?: InputMaybe<Scalars['Boolean']>;
  valor_pago: Scalars['Float'];
};


export type MutationBaixarCobrancasArgs = {
  baixas: Array<BaixarInput>;
  bancoid?: InputMaybe<Scalars['Int']>;
  caixaid?: InputMaybe<Scalars['Int']>;
  cartaoCredito?: InputMaybe<Scalars['Float']>;
  cartaoDebito?: InputMaybe<Scalars['Float']>;
  dinheiro?: InputMaybe<Scalars['Float']>;
  imprimirRecibo?: InputMaybe<Scalars['Boolean']>;
  pix?: InputMaybe<Scalars['Float']>;
};


export type MutationBaixarPagarArgs = {
  data_pago: Scalars['DateTime'];
  id: Scalars['Int'];
  valor_pago: Scalars['Float'];
};


export type MutationCancelarCobrancaArgs = {
  id: Scalars['Int'];
};


export type MutationCancelarContratoArgs = {
  id: Scalars['Int'];
  motivoCancelamento: MotivoCancelamento;
  outroMotivoCancelamento?: InputMaybe<Scalars['String']>;
};


export type MutationChangeMyPasswordArgs = {
  novaSenha: Scalars['String'];
  senhaAtual: Scalars['String'];
};


export type MutationChangePainelPasswordArgs = {
  novaSenha: Scalars['String'];
  senhaAtual: Scalars['String'];
};


export type MutationCheckContratoArgs = {
  id: Scalars['Int'];
};


export type MutationConfirmEmailArgs = {
  codConfirmacao: Scalars['String'];
  email: Scalars['String'];
};


export type MutationConfirmResetPasswordArgs = {
  codConfirmacao: Scalars['String'];
  email: Scalars['String'];
  novaSenha: Scalars['String'];
};


export type MutationConsultaCobrancaOnlineArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateBancoArgs = {
  banco: BancoInput;
};


export type MutationCreateCaixaArgs = {
  caixa: CaixaInput;
};


export type MutationCreateChamadoArgs = {
  chamado: CreateChamado;
};


export type MutationCreateClienteArgs = {
  cliente: ClienteInput;
};


export type MutationCreateCobrancaArgs = {
  cobranca: CobrancaInput;
};


export type MutationCreateContratoArgs = {
  contrato: ContratoInput;
  vezesInstalacao: Scalars['Int'];
};


export type MutationCreateEstacaoArgs = {
  estacao: EstacaoInput;
  senha: Scalars['String'];
};


export type MutationCreateLancamento_CaixaArgs = {
  lancamento_caixa: Lancamento_CaixaInput;
};


export type MutationCreateLancamentobancoArgs = {
  lancamentobanco: LancamentobancoInput;
};


export type MutationCreateNotaArgs = {
  nota: NotaInput;
};


export type MutationCreatePagarArgs = {
  pagar: PagarInput;
};


export type MutationCreatePlanoArgs = {
  plano: PlanoInput;
};


export type MutationCreateProdutoArgs = {
  produto: ProdutoInput;
};


export type MutationCreateSerieArgs = {
  serie: SerieInput;
};


export type MutationCreateUsuarioArgs = {
  isActive: Scalars['Boolean'];
  role: Scalars['String'];
  senha: Scalars['String'];
  usuario: Scalars['String'];
};


export type MutationCreateVelocidadeArgs = {
  velocidade: VelocidadeInput;
};


export type MutationCriarPixImediatoArgs = {
  id: Scalars['Int'];
};


export type MutationCriarPixPainelArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteBancoArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteCaixaArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteChamadoArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteClienteArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteCobrancaArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteContratoArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteEstacaoArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteLancamento_CaixaArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteLancamentobancoArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteNotaArgs = {
  id: Scalars['Int'];
};


export type MutationDeletePagarArgs = {
  id: Scalars['Int'];
};


export type MutationDeletePlanoArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteProdutoArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteSerieArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteUsuarioArgs = {
  usuario: Scalars['String'];
};


export type MutationDeleteVelocidadeArgs = {
  id: Scalars['Int'];
};


export type MutationEmitirCobrancaWsArgs = {
  id: Scalars['Int'];
};


export type MutationFecharBancoArgs = {
  bancoid: Scalars['Int'];
  saldo: Scalars['Float'];
};


export type MutationFecharCaixaArgs = {
  caixaid: Scalars['Int'];
  saldo: Scalars['Float'];
};


export type MutationGeraArquivoCnabArgs = {
  bancoid: Scalars['Int'];
  data_final: Scalars['DateTime'];
  data_inicial: Scalars['DateTime'];
};


export type MutationGeraArquivosNf21Args = {
  mes: Scalars['DateTime'];
  serieId: Scalars['Int'];
};


export type MutationGeraEEnviaNfArgs = {
  mes: Scalars['DateTime'];
};


export type MutationGerarNotasArgs = {
  emissao: Scalars['DateTime'];
  produtoId: Scalars['Int'];
  qtd: Scalars['Int'];
};


export type MutationImprimirFechamentoArgs = {
  id: Scalars['Int'];
};


export type MutationImprimirLCaixaArgs = {
  id: Scalars['Int'];
};


export type MutationImprimirReciboArgs = {
  id: Scalars['Int'];
};


export type MutationLeArquivoCnabArgs = {
  arquivob64: Scalars['String'];
  bancoid: Scalars['Int'];
};


export type MutationLoginArgs = {
  senha: Scalars['String'];
  usuario: Scalars['String'];
};


export type MutationLoginClienteArgs = {
  email: Scalars['String'];
  senha: Scalars['String'];
};


export type MutationMarcarImpressoArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationMudarBancoCobrancasArgs = {
  banco_destino: Scalars['Int'];
  banco_origem: Scalars['Int'];
  data_final: Scalars['DateTime'];
  data_inicial: Scalars['DateTime'];
};


export type MutationRegisterArgs = {
  celular: Scalars['String'];
  cpfcnpj: Scalars['String'];
  email: Scalars['String'];
  senha: Scalars['String'];
};


export type MutationRequestResetPasswordArgs = {
  cpfcnpj: Scalars['String'];
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  novaSenha: Scalars['String'];
  usuario: Scalars['String'];
};


export type MutationSyncContratoArgs = {
  id: Scalars['Int'];
};


export type MutationSyncEstacaoArgs = {
  id: Scalars['Int'];
};


export type MutationTestarPlanoArgs = {
  id: Scalars['Int'];
  planoId: Scalars['Int'];
};


export type MutationUpdateBancoArgs = {
  banco: BancoInput;
  numeracaoCNAB?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateCaixaArgs = {
  caixa: CaixaInput;
};


export type MutationUpdateClienteArgs = {
  cliente: ClienteInput;
};


export type MutationUpdateCobrancaArgs = {
  cobranca: CobrancaInput;
};


export type MutationUpdateContratoArgs = {
  contrato: ContratoInput;
};


export type MutationUpdateEstacaoArgs = {
  estacao: EstacaoInput;
  senha?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateNotaArgs = {
  nota: NotaInput;
};


export type MutationUpdatePagarArgs = {
  pagar: PagarInput;
};


export type MutationUpdatePlanoArgs = {
  plano: PlanoInput;
};


export type MutationUpdateProdutoArgs = {
  produto: ProdutoInput;
};


export type MutationUpdateSerieArgs = {
  numeracao?: InputMaybe<Scalars['Int']>;
  serie: SerieInput;
};


export type MutationUpdateUsuarioArgs = {
  isActive: Scalars['Boolean'];
  role: Scalars['String'];
  usuario: Scalars['String'];
};


export type MutationUpdateVelocidadeArgs = {
  velocidade: VelocidadeInput;
};


export type MutationVerificarEBaixarCobrancasArgs = {
  data_final?: InputMaybe<Scalars['DateTime']>;
  data_inicial?: InputMaybe<Scalars['DateTime']>;
};

export type MutationGeraArquivoResponse = {
  __typename?: 'MutationGeraArquivoResponse';
  conteudoArquivoDICI: Scalars['String'];
  conteudoArquivoDados: Scalars['String'];
  conteudoArquivoItem: Scalars['String'];
  conteudoArquivoMestre: Scalars['String'];
  nomeArquivoDICI: Scalars['String'];
  nomeArquivoDados: Scalars['String'];
  nomeArquivoItem: Scalars['String'];
  nomeArquivoMestre: Scalars['String'];
};

export type MutationGeraCnabResponse = {
  __typename?: 'MutationGeraCNABResponse';
  conteudoArquivo: Scalars['String'];
  nomeArquivo: Scalars['String'];
};

export type Nota = {
  __typename?: 'Nota';
  autenticacao: Scalars['String'];
  bcicms: Scalars['Float'];
  cliente?: Maybe<Cliente>;
  emissao: Scalars['DateTime'];
  icms: Scalars['Float'];
  id?: Maybe<Scalars['Int']>;
  itens: Array<ItemNota>;
  modelo: Scalars['String'];
  numero: Scalars['String'];
  outrovalor: Scalars['Float'];
  serie?: Maybe<Serie>;
  tipoassinante: Scalars['Float'];
  tipoutilizacao: Scalars['Float'];
  valorisento: Scalars['Float'];
  valortotal: Scalars['Float'];
};

export type NotaInput = {
  cliente: Scalars['Int'];
  emissao: Scalars['DateTime'];
  id?: InputMaybe<Scalars['Int']>;
  itens: Array<ItemNotaInput>;
  modelo: Scalars['String'];
  numero: Scalars['String'];
  serie: Scalars['Int'];
};

export type NotaResponse = {
  __typename?: 'NotaResponse';
  errors?: Maybe<Array<FieldError>>;
  nota?: Maybe<Nota>;
};

export type OrderByInput = {
  orderField: Scalars['String'];
  orderType?: InputMaybe<Scalars['String']>;
};

export type Pagar = {
  __typename?: 'Pagar';
  data_pago?: Maybe<Scalars['DateTime']>;
  descricao: Scalars['String'];
  fornecedor: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
  isPago: Scalars['Boolean'];
  numero: Scalars['String'];
  valor: Scalars['Float'];
  valor_pago: Scalars['Float'];
  vencimento: Scalars['DateTime'];
};

export type PagarInput = {
  descricao: Scalars['String'];
  fornecedor: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  numero: Scalars['String'];
  valor: Scalars['Float'];
  vencimento: Scalars['DateTime'];
};

export type PagarResponse = {
  __typename?: 'PagarResponse';
  errors?: Maybe<Array<FieldError>>;
  pagar?: Maybe<Pagar>;
};

export type Plano = {
  __typename?: 'Plano';
  descontoMensalidade: Scalars['Float'];
  diaUso: Scalars['Float'];
  diaVencimento: Scalars['Float'];
  geraAuto: Scalars['Boolean'];
  id?: Maybe<Scalars['Int']>;
  instrucoesMensalidade: Scalars['String'];
  jurosMensalidade: Scalars['Float'];
  mensalidade: Scalars['Float'];
  multaMensalidade: Scalars['Float'];
  nome: Scalars['String'];
  qtdAuto: Scalars['Float'];
  status: Scalars['Boolean'];
  valorInstalacao: Scalars['Float'];
  velocidade: Scalars['String'];
  velocidadePlano?: Maybe<Velocidade>;
  vezesInstalacao: Scalars['Float'];
};

export type PlanoInput = {
  descontoMensalidade: Scalars['Float'];
  diaUso: Scalars['Float'];
  diaVencimento: Scalars['Float'];
  geraAuto: Scalars['Boolean'];
  id?: InputMaybe<Scalars['Int']>;
  instrucoesMensalidade: Scalars['String'];
  jurosMensalidade: Scalars['Float'];
  mensalidade: Scalars['Float'];
  multaMensalidade: Scalars['Float'];
  nome: Scalars['String'];
  qtdAuto: Scalars['Float'];
  status: Scalars['Boolean'];
  valorInstalacao: Scalars['Float'];
  velocidade: Scalars['String'];
  velocidadePlano?: InputMaybe<VelocidadeInput>;
  vezesInstalacao: Scalars['Float'];
};

export type PlanoResponse = {
  __typename?: 'PlanoResponse';
  errors?: Maybe<Array<FieldError>>;
  plano?: Maybe<Plano>;
};

export type Produto = {
  __typename?: 'Produto';
  classe: Scalars['Float'];
  grupo: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
  nome: Scalars['String'];
  plano?: Maybe<Plano>;
  valor: Scalars['Float'];
  velocidade: Scalars['String'];
};

export type ProdutoInput = {
  classe: Scalars['Float'];
  grupo: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  nome: Scalars['String'];
  plano?: InputMaybe<PlanoInput>;
  valor: Scalars['Float'];
  velocidade: Scalars['String'];
};

export type ProdutoResponse = {
  __typename?: 'ProdutoResponse';
  errors?: Maybe<Array<FieldError>>;
  produto?: Maybe<Produto>;
};

export type Query = {
  __typename?: 'Query';
  banco?: Maybe<QueryBancoResponse>;
  bancos: QueryBancosResponse;
  boletoInstalacaoContrato?: Maybe<Cobranca>;
  buscaConvenio: Array<Cobranca>;
  caixa?: Maybe<Caixa>;
  caixas: QueryCaixaResponse;
  chamado?: Maybe<Chamado>;
  chamados: QueryChamadoResponse;
  cliente?: Maybe<Cliente>;
  clientes: QueryClienteResponse;
  cobranca?: Maybe<Cobranca>;
  cobrancas: QueryCobrancaResponse;
  contrato?: Maybe<Contrato>;
  contratos: QueryContratoResponse;
  estacao?: Maybe<Estacao>;
  estacaos: QueryEstacaoResponse;
  hello: Scalars['String'];
  lancamento_caixa?: Maybe<Lancamento_Caixa>;
  lancamento_caixas: QueryLancamento_CaixaResponse;
  lancamentobanco?: Maybe<Lancamentobanco>;
  lancamentobancos: QueryLancamentobancoResponse;
  me?: Maybe<Usuario>;
  nota?: Maybe<Nota>;
  notas: QueryNotaResponse;
  pagar?: Maybe<Pagar>;
  pagars: QueryPagarResponse;
  painelCliente?: Maybe<Cliente>;
  plano?: Maybe<Plano>;
  planos: QueryPlanoResponse;
  produto?: Maybe<Produto>;
  produtos: QueryProdutoResponse;
  serie?: Maybe<QuerySerieResponse>;
  series: QuerySeriesResponse;
  usuario?: Maybe<Usuario>;
  usuarios: Array<Usuario>;
  velocidade?: Maybe<Velocidade>;
  velocidades: QueryVelocidadeResponse;
};


export type QueryBancoArgs = {
  id: Scalars['Int'];
};


export type QueryBancosArgs = {
  filter?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};


export type QueryBoletoInstalacaoContratoArgs = {
  id: Scalars['Int'];
};


export type QueryBuscaConvenioArgs = {
  data_final: Scalars['DateTime'];
  data_inicial: Scalars['DateTime'];
  tipoConvenio: TipoConvenio;
};


export type QueryCaixaArgs = {
  id: Scalars['Int'];
};


export type QueryCaixasArgs = {
  filter?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryChamadoArgs = {
  id: Scalars['Int'];
};


export type QueryChamadosArgs = {
  clienteid?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Array<StatusChamado>>;
};


export type QueryClienteArgs = {
  id: Scalars['Int'];
};


export type QueryClientesArgs = {
  filter?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryCobrancaArgs = {
  id: Scalars['Int'];
};


export type QueryCobrancasArgs = {
  carteira?: InputMaybe<Scalars['String']>;
  cliente?: InputMaybe<Scalars['Int']>;
  contrato?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  isPago?: InputMaybe<Scalars['Boolean']>;
  isPrinted?: InputMaybe<Scalars['Boolean']>;
  orderBy?: InputMaybe<Array<OrderByInput>>;
  pagamento?: InputMaybe<DateRangeInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  vencimento?: InputMaybe<DateRangeInput>;
};


export type QueryContratoArgs = {
  id: Scalars['Int'];
};


export type QueryContratosArgs = {
  clienteid?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryEstacaoArgs = {
  id: Scalars['Int'];
};


export type QueryEstacaosArgs = {
  filter?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryLancamento_CaixaArgs = {
  id: Scalars['Int'];
};


export type QueryLancamento_CaixasArgs = {
  caixaid: Scalars['Int'];
  data_final: Scalars['DateTime'];
  data_inicial: Scalars['DateTime'];
  filter?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryLancamentobancoArgs = {
  id: Scalars['Int'];
};


export type QueryLancamentobancosArgs = {
  bancoid: Scalars['Int'];
  data_final: Scalars['DateTime'];
  data_inicial: Scalars['DateTime'];
  filter?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryNotaArgs = {
  id: Scalars['Int'];
};


export type QueryNotasArgs = {
  emissao?: InputMaybe<DateRangeInput>;
  filter?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryPagarArgs = {
  id: Scalars['Int'];
};


export type QueryPagarsArgs = {
  filter?: InputMaybe<Scalars['String']>;
  isPago?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  vencimento?: InputMaybe<DateRangeInput>;
};


export type QueryPlanoArgs = {
  id: Scalars['Int'];
};


export type QueryPlanosArgs = {
  filter?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Boolean']>;
};


export type QueryProdutoArgs = {
  id: Scalars['Int'];
};


export type QueryProdutosArgs = {
  filter?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};


export type QuerySerieArgs = {
  id: Scalars['Int'];
};


export type QuerySeriesArgs = {
  filter?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryUsuarioArgs = {
  usuario: Scalars['String'];
};


export type QueryVelocidadeArgs = {
  id: Scalars['Int'];
};


export type QueryVelocidadesArgs = {
  filter?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryBancoResponse = {
  __typename?: 'QueryBancoResponse';
  data: Banco;
  numeracaoCNAB: Scalars['Int'];
};

export type QueryBancosResponse = {
  __typename?: 'QueryBancosResponse';
  data: Array<Banco>;
  totalPages: Scalars['Int'];
};

export type QueryCaixaResponse = {
  __typename?: 'QueryCaixaResponse';
  data: Array<Caixa>;
  totalPages: Scalars['Int'];
};

export type QueryChamadoResponse = {
  __typename?: 'QueryChamadoResponse';
  data: Array<Chamado>;
  totalPages: Scalars['Int'];
};

export type QueryClienteResponse = {
  __typename?: 'QueryClienteResponse';
  data: Array<Cliente>;
  totalPages: Scalars['Int'];
};

export type QueryCobrancaResponse = {
  __typename?: 'QueryCobrancaResponse';
  data: Array<Cobranca>;
  totalPages: Scalars['Int'];
};

export type QueryContratoResponse = {
  __typename?: 'QueryContratoResponse';
  data: Array<Contrato>;
  totalPages: Scalars['Int'];
};

export type QueryEstacaoResponse = {
  __typename?: 'QueryEstacaoResponse';
  data: Array<Estacao>;
  totalPages: Scalars['Int'];
};

export type QueryLancamento_CaixaResponse = {
  __typename?: 'QueryLancamento_caixaResponse';
  data: Array<Lancamento_Caixa>;
  saldoAnterior?: Maybe<Scalars['Float']>;
  totalPages: Scalars['Int'];
};

export type QueryLancamentobancoResponse = {
  __typename?: 'QueryLancamentobancoResponse';
  data: Array<Lancamentobanco>;
  saldoAnterior?: Maybe<Scalars['Float']>;
  totalPages: Scalars['Int'];
};

export type QueryNotaResponse = {
  __typename?: 'QueryNotaResponse';
  data: Array<Nota>;
  totalPages: Scalars['Int'];
};

export type QueryPagarResponse = {
  __typename?: 'QueryPagarResponse';
  data: Array<Pagar>;
  totalPages: Scalars['Int'];
};

export type QueryPlanoResponse = {
  __typename?: 'QueryPlanoResponse';
  data: Array<Plano>;
  totalPages: Scalars['Int'];
};

export type QueryProdutoResponse = {
  __typename?: 'QueryProdutoResponse';
  data: Array<Produto>;
  totalPages: Scalars['Int'];
};

export type QuerySerieResponse = {
  __typename?: 'QuerySerieResponse';
  data: Serie;
  numeracao: Scalars['Int'];
};

export type QuerySeriesResponse = {
  __typename?: 'QuerySeriesResponse';
  data: Array<Serie>;
  totalPages: Scalars['Int'];
};

export type QueryVelocidadeResponse = {
  __typename?: 'QueryVelocidadeResponse';
  data: Array<Velocidade>;
  totalPages: Scalars['Int'];
};

export type ResultadoLeitura = {
  __typename?: 'ResultadoLeitura';
  cobranca?: Maybe<Cobranca>;
  linha?: Maybe<Scalars['String']>;
  movimento?: Maybe<Scalars['String']>;
  nomeCliente?: Maybe<Scalars['String']>;
  nossoNumero?: Maybe<Scalars['String']>;
  numeroDocumento?: Maybe<Scalars['String']>;
};

export type ResultadoProcessoWs = {
  __typename?: 'ResultadoProcessoWS';
  dataFimConsulta: Scalars['DateTime'];
  dataInicioConsulta: Scalars['DateTime'];
  resultadoProcessamento: Array<ItemResultadoProcessoWs>;
};

export type RetornoArquivoResponse = {
  __typename?: 'RetornoArquivoResponse';
  resultadoLeitura: Array<ResultadoLeitura>;
};

export type Serie = {
  __typename?: 'Serie';
  codigo: Scalars['String'];
  descricao: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
};

export type SerieInput = {
  codigo: Scalars['String'];
  descricao: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
};

export type SerieResponse = {
  __typename?: 'SerieResponse';
  errors?: Maybe<Array<FieldError>>;
  serie?: Maybe<Serie>;
};

export enum StatusChamado {
  Aguardando = 'AGUARDANDO',
  Emandamento = 'EMANDAMENTO',
  Fechado = 'FECHADO',
  Iniciado = 'INICIADO',
  Material = 'MATERIAL'
}

export enum TipoAcesso {
  Hotspot = 'HOTSPOT',
  Ppp = 'PPP',
  Radius = 'RADIUS',
  Vlan = 'VLAN'
}

export enum TipoChamado {
  Instalacao = 'INSTALACAO',
  Manutencao = 'MANUTENCAO',
  Mudanca = 'MUDANCA',
  Retirdada = 'RETIRDADA'
}

export enum TipoConvenio {
  Moinhoglobo = 'MOINHOGLOBO',
  Nenhum = 'NENHUM',
  Trocaservicos = 'TROCASERVICOS'
}

export enum TipoEstacao {
  Mikrotik = 'MIKROTIK',
  Ubiquiti = 'UBIQUITI'
}

export type Usuario = {
  __typename?: 'Usuario';
  isActive: Scalars['Boolean'];
  role: Scalars['String'];
  usuario: Scalars['String'];
};

export type UsuarioResponse = {
  __typename?: 'UsuarioResponse';
  errors?: Maybe<Array<FieldError>>;
  usuario?: Maybe<Usuario>;
};

export type Velocidade = {
  __typename?: 'Velocidade';
  avgDownload: Scalars['Float'];
  avgUpload: Scalars['Float'];
  burst: Scalars['Boolean'];
  burstDownload: Scalars['Float'];
  burstUpload: Scalars['Float'];
  download: Scalars['Float'];
  id?: Maybe<Scalars['Int']>;
  nome: Scalars['String'];
  prioDownload: Scalars['Float'];
  prioUpload: Scalars['Float'];
  timeDownload: Scalars['Float'];
  timeUpload: Scalars['Float'];
  upload: Scalars['Float'];
};

export type VelocidadeInput = {
  avgDownload: Scalars['Float'];
  avgUpload: Scalars['Float'];
  burst: Scalars['Boolean'];
  burstDownload: Scalars['Float'];
  burstUpload: Scalars['Float'];
  download: Scalars['Float'];
  id?: InputMaybe<Scalars['Int']>;
  nome: Scalars['String'];
  prioDownload: Scalars['Float'];
  prioUpload: Scalars['Float'];
  timeDownload: Scalars['Float'];
  timeUpload: Scalars['Float'];
  upload: Scalars['Float'];
};

export type VelocidadeResponse = {
  __typename?: 'VelocidadeResponse';
  errors?: Maybe<Array<FieldError>>;
  velocidade?: Maybe<Velocidade>;
};

export type ChangePainelPasswordMutationVariables = Exact<{
  senhaAtual: Scalars['String'];
  novaSenha: Scalars['String'];
}>;


export type ChangePainelPasswordMutation = { __typename?: 'Mutation', changePainelPassword: boolean };

export type ConfirmEmailMutationVariables = Exact<{
  email: Scalars['String'];
  codConfirmacao: Scalars['String'];
}>;


export type ConfirmEmailMutation = { __typename?: 'Mutation', confirmEmail: boolean };

export type ConfirmResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  novaSenha: Scalars['String'];
  codConfirmacao: Scalars['String'];
}>;


export type ConfirmResetPasswordMutation = { __typename?: 'Mutation', confirmResetPassword: boolean };

export type CriarPixPainelMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CriarPixPainelMutation = { __typename?: 'Mutation', criarPixPainel: { __typename?: 'Cobranca', id?: number | null | undefined, data_emissao: any, valor: number, desconto: number, juros: number, vencimento: any, referencia?: string | null | undefined, valor_pago?: number | null | undefined, data_pago?: any | null | undefined, isPago: boolean, isCanceled: boolean, isRegistrado: boolean, codClienteBol?: string | null | undefined, dvCodClienteBol?: string | null | undefined, copiaECola?: string | null | undefined, qrCode?: string | null | undefined, banco: { __typename?: 'Banco', id?: number | null | undefined, codigo: string, agencia: string, agenciaDv: string, conta: string, contaDv: string, codCliente: string, codClienteDv: string, carteira: string } } };

export type LoginClienteMutationVariables = Exact<{
  senha: Scalars['String'];
  email: Scalars['String'];
}>;


export type LoginClienteMutation = { __typename?: 'Mutation', loginCliente: { __typename?: 'Cliente', id?: number | null | undefined } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type PainelClienteQueryVariables = Exact<{ [key: string]: never; }>;


export type PainelClienteQuery = { __typename?: 'Query', painelCliente?: { __typename?: 'Cliente', id?: number | null | undefined, nome: string, contratos?: Array<{ __typename?: 'Contrato', logradouro: string, numero: string, bairro: string, cidade: string, uf: string, usuario?: string | null | undefined, isCanceled: boolean, isBlocked: boolean, isAtivado: boolean, cobrancas?: Array<{ __typename?: 'Cobranca', id?: number | null | undefined, data_emissao: any, valor: number, desconto: number, juros: number, vencimento: any, referencia?: string | null | undefined, valor_pago?: number | null | undefined, data_pago?: any | null | undefined, isPago: boolean, isCanceled: boolean, isRegistrado: boolean, codClienteBol?: string | null | undefined, dvCodClienteBol?: string | null | undefined, banco: { __typename?: 'Banco', id?: number | null | undefined, codigo: string, agencia: string, agenciaDv: string, conta: string, contaDv: string, codCliente: string, codClienteDv: string, carteira: string } }> | null | undefined }> | null | undefined } | null | undefined };

export type RegisterMutationVariables = Exact<{
  celular: Scalars['String'];
  senha: Scalars['String'];
  email: Scalars['String'];
  cpfcnpj: Scalars['String'];
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: boolean };

export type RequestResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  cpfcnpj: Scalars['String'];
}>;


export type RequestResetPasswordMutation = { __typename?: 'Mutation', requestResetPassword: boolean };


export const ChangePainelPasswordDocument = gql`
    mutation ChangePainelPassword($senhaAtual: String!, $novaSenha: String!) {
  changePainelPassword(senhaAtual: $senhaAtual, novaSenha: $novaSenha)
}
    `;

export function useChangePainelPasswordMutation() {
  return Urql.useMutation<ChangePainelPasswordMutation, ChangePainelPasswordMutationVariables>(ChangePainelPasswordDocument);
};
export const ConfirmEmailDocument = gql`
    mutation ConfirmEmail($email: String!, $codConfirmacao: String!) {
  confirmEmail(email: $email, codConfirmacao: $codConfirmacao)
}
    `;

export function useConfirmEmailMutation() {
  return Urql.useMutation<ConfirmEmailMutation, ConfirmEmailMutationVariables>(ConfirmEmailDocument);
};
export const ConfirmResetPasswordDocument = gql`
    mutation ConfirmResetPassword($email: String!, $novaSenha: String!, $codConfirmacao: String!) {
  confirmResetPassword(
    email: $email
    novaSenha: $novaSenha
    codConfirmacao: $codConfirmacao
  )
}
    `;

export function useConfirmResetPasswordMutation() {
  return Urql.useMutation<ConfirmResetPasswordMutation, ConfirmResetPasswordMutationVariables>(ConfirmResetPasswordDocument);
};
export const CriarPixPainelDocument = gql`
    mutation CriarPixPainel($id: Int!) {
  criarPixPainel(id: $id) {
    id
    data_emissao
    valor
    desconto
    juros
    vencimento
    referencia
    valor_pago
    data_pago
    isPago
    isCanceled
    isRegistrado
    codClienteBol
    dvCodClienteBol
    copiaECola
    qrCode
    banco {
      id
      codigo
      agencia
      agenciaDv
      conta
      contaDv
      codCliente
      codClienteDv
      carteira
    }
  }
}
    `;

export function useCriarPixPainelMutation() {
  return Urql.useMutation<CriarPixPainelMutation, CriarPixPainelMutationVariables>(CriarPixPainelDocument);
};
export const LoginClienteDocument = gql`
    mutation LoginCliente($senha: String!, $email: String!) {
  loginCliente(senha: $senha, email: $email) {
    id
  }
}
    `;

export function useLoginClienteMutation() {
  return Urql.useMutation<LoginClienteMutation, LoginClienteMutationVariables>(LoginClienteDocument);
};
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;

export function useLogoutMutation() {
  return Urql.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument);
};
export const PainelClienteDocument = gql`
    query PainelCliente {
  painelCliente {
    id
    nome
    contratos {
      logradouro
      numero
      bairro
      cidade
      uf
      usuario
      isCanceled
      isBlocked
      isAtivado
      cobrancas {
        id
        data_emissao
        valor
        desconto
        juros
        vencimento
        referencia
        valor_pago
        data_pago
        isPago
        isCanceled
        isRegistrado
        codClienteBol
        dvCodClienteBol
        banco {
          id
          codigo
          agencia
          agenciaDv
          conta
          contaDv
          codCliente
          codClienteDv
          carteira
        }
      }
    }
  }
}
    `;

export function usePainelClienteQuery(options: Omit<Urql.UseQueryArgs<PainelClienteQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<PainelClienteQuery>({ query: PainelClienteDocument, ...options });
};
export const RegisterDocument = gql`
    mutation Register($celular: String!, $senha: String!, $email: String!, $cpfcnpj: String!) {
  register(celular: $celular, senha: $senha, email: $email, cpfcnpj: $cpfcnpj)
}
    `;

export function useRegisterMutation() {
  return Urql.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument);
};
export const RequestResetPasswordDocument = gql`
    mutation RequestResetPassword($email: String!, $cpfcnpj: String!) {
  requestResetPassword(email: $email, cpfcnpj: $cpfcnpj)
}
    `;

export function useRequestResetPasswordMutation() {
  return Urql.useMutation<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>(RequestResetPasswordDocument);
};