import { Button, Link, Flex, Heading, Image } from "@chakra-ui/react";
import { navigate, RouteComponentProps, useLocation } from "@reach/router";
import { Form, Formik } from "formik";
import React from "react";
import InputField from "../../components/InputField";
import logo from "../../assets/logo.png";
import { useLoginClienteMutation } from "../../generated/graphql";
import queryString from "query-string";

export const Login: React.FC<RouteComponentProps> = () => {
  const location = useLocation();
  const query = queryString.parse(location.search);

  const [, login] = useLoginClienteMutation();

  return (
    <Flex
      direction="column"
      mx="auto"
      justifyContent="center"
      alignItems="center"
      minH="100vh"
      px="0.5rem"
    >
      <Flex
        direction="column"
        px="2rem"
        py="0.5rem"
        maxWidth={["100%", "40%"]}
        backgroundColor="white"
        borderWidth="2px"
        borderColor="black"
        rounded="md"
      >
        <Image src={logo} maxWidth="25ch" mx="auto" mb="2rem" mt="1rem" />
        <Heading mb="2rem">Login no Painel do usuário</Heading>
        <Formik
          initialValues={{
            email: "",
            senha: "",
          }}
          onSubmit={async (values, { setErrors }) => {
            console.log("login submit", values);
            const res = await login(values);
            if (res.error) {
              setErrors({ senha: res.error.message.replace("[GraphQL]", "") });
              return;
            }
            const redirUrl = (query.next as string) || "/";
            console.log("redir url", redirUrl, /.*login.*/.test(redirUrl));
            if (/.*login.*/.test(redirUrl)) {
              navigate("/");
              return;
            }
            navigate(redirUrl);
          }}
        >
          {(props) => (
            <Form>
              <InputField
                autoFocus
                name="email"
                placeholder="Email"
                label="Email"
              />
              <InputField
                autoFocus
                name="senha"
                placeholder="Senha"
                type="password"
                label="Senha"
              />
              <Button
                mt={4}
                ml="auto"
                colorScheme="teal"
                isLoading={props.isSubmitting}
                type="submit"
              >
                Entrar
              </Button>
              <Flex
                mt="1rem"
                mb="2rem"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Link href="/register">Não tenho cadastro</Link>
                <Link href="/forgot">Esqueci minha senha</Link>
              </Flex>
            </Form>
          )}
        </Formik>
      </Flex>
    </Flex>
  );
};
